import React from 'react'
import { IntlContext } from 'react-intl'
import { graphql } from 'gatsby'
//
import {
  ContentfulRecipe,
  ContentfulSettings,
  Site,
  ContentfulRecipeConnection,
  ContentfulTechniqueConnection
} from '~generated/gatsby.types'
import { MainLayout } from '~layout'
import { buildPageMetaTags } from '~utils'
import {
  IngredientsEquipments,
  StepsList,
  IngredientsEquipmentsListing,
  StepsGrid
} from '~components/template-components'
import { Tip, SecondaryHero, ContentCarousel, VotingBlock } from '~components/page-components'
import { getEntryPrefix } from '~utils/common'
import theme from '~theme'

type PageContextType = {
  id: number
  node_locale: string
}

type PageQuery = {
  site: Site
  contentfulRecipe: ContentfulRecipe
  contentfulSettings: ContentfulSettings
  allContentfulRecipe: ContentfulRecipeConnection
  allContentfulTechnique: ContentfulTechniqueConnection
}

type PageProps = {
  data: PageQuery
  pageContext: PageContextType
}

const Recipe = ({ data }: PageProps) => {
  const { site, contentfulRecipe, allContentfulRecipe, contentfulSettings } = data
  const intl: any = React.useContext(IntlContext)
  const {
    id,
    title,
    image,
    categories,
    shortDescription,
    ingredientsEquipment,
    baristaTip,
    cta,
    steps,
    votingBlock
  } = contentfulRecipe
  const {
    genericTipText,
    genericTipQuote,
    genericTipImage,
    recipeContentCarouselTitle,
    recipeContentCarouselShortDescription,
    recipeContentCarouselCta
  } = contentfulSettings

  const mappedCategories = categories?.filter(cat => cat?.title).map(cat => cat?.id) || []

  const recipes = allContentfulRecipe.edges
    .map(edge => ({
      ...edge.node,
      matches: edge?.node?.categories?.some((category: any) => mappedCategories.includes(category.id)) ? 1 : 0
    }))
    .sort((a, b) => b.matches - a.matches)

  return (
    <MainLayout metaTags={buildPageMetaTags(contentfulRecipe, site)} contentfulSettings={contentfulSettings}>
      <SecondaryHero
        prefix={getEntryPrefix('recipe')}
        title={title}
        shortDescription={shortDescription}
        image={image}
        mediaOrientation="Left"
        cta={cta}
        showSocialShare
      />

      {ingredientsEquipment && (
        <IngredientsEquipmentsListing
          id={`recipe-ingredients-${id}`}
          items={ingredientsEquipment}
          contentfulSettings={contentfulSettings}
          desktopDivider="top"
        />
      )}

      <StepsGrid steps={steps} />

      <Tip
        {...baristaTip}
        prefix={intl.formatMessage({ id: 'tip.category' })}
        genericTipText={genericTipText}
        genericTipQuote={genericTipQuote}
        genericTipImage={genericTipImage}
      />

      {votingBlock && (
        <VotingBlock
          contentfulSettings={contentfulSettings}
          {...votingBlock}
          bgColorOverride={theme.colors.oatmealBrown}
        />
      )}

      <ContentCarousel
        id={`recipe-${id}`}
        title={recipeContentCarouselTitle}
        shortDescription={recipeContentCarouselShortDescription}
        contents={recipes}
        cta={recipeContentCarouselCta}
        bgColorOverride={theme.colors.white}
      />
    </MainLayout>
  )
}

export default Recipe

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    contentfulRecipe(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      __typename
      id
      title
      categories {
        title
        id
      }
      shortDescription {
        shortDescription
      }
      description {
        json
      }
      cta {
        title
        externalUrl
        content {
          ...FragmentNavigationElement
        }
      }
      steps {
        ... on ContentfulStep {
          ...FragmentStep
        }
      }
      ingredientsEquipment {
        ... on ContentfulEquipment {
          ...FragmentEquipment
        }
        ... on ContentfulIngredient {
          ...FragmentIngredient
        }
      }
      votingBlock {
        ... on ContentfulVotingBlock {
          ...FragmentVotingBlock
        }
      }
      image {
        bynder: bynderAsset {
          fluid(maxWidth: 1920, quality: 80) {
            ...QueryImageFluid
          }
        }
        local: localAsset {
          fluid(maxWidth: 1920, quality: 80) {
            ...QueryImageFluid
          }
        }
      }
      baristaTip {
        ...FragmentTip
      }
      metaTags {
        title
        description
        noindex
        nofollow
        keywords
        canonical
      }
    }

    allContentfulRecipe(filter: { id: { ne: $id } }, sort: { fields: publishedDate, order: DESC }, limit: 8) {
      edges {
        node {
          __typename
          id
          title
          slug
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    site {
      ...FragmentSite
    }
  }
`
